.details {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "detailsheader" "detailsbody" "detailsfooter";
    background-color: #6C81F0;
    margin: 0 auto;
}

.details .details-content {
    margin: 16px;
}

.details .details-header {
    grid-area: detailsheader;
    width: 100%;
}


.details .details-footer {
    grid-area: detailsfooter;
    height: 16px;
    width: 100%;
}

.details .details-topbar {
    display: grid;
    grid-template-columns: 64px 1fr 64px;
}

.details .details-topbar .icon {
    width: 24px;
}

.details .details-topbar .center {
    text-align: center;
}

.details .details-topbar .right {
    text-align: right;
}

.details .details-body {
    grid-area: detailsbody;
    margin: 0 16px;
    height: 100%;
    overflow-y: hidden;
}

.details .details-body li {
    height: 64px;
}

.item-cover {
    width: 96px;
    background-position: center;
    background-size: cover;
    height: 128px;
    border-radius: 4px;
    position: relative;
}

.item-cover .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.25) 100%);
    border-radius: 4px;
    overflow: hidden;
}

.details-info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "infoleft inforight";
    gap: 16px;
    margin: 16px 0;
}

.details-info .left {
    grid-area: infoleft;
}

.details-info .right {
    grid-area: inforight;
}

.details-info p {
    font-size: small;
}

.details .info-actions {
    margin-top: 8px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-areas: "div div div";
    gap: 4px;
    align-items: start;
}


.details .details-info .icon {
    width: 24px;
    margin: 0 8px;
}

.details .details-info .creator-info {
    font-size: small;
}

.details .details-info .creator-info a {
    font-weight: 600;
}

.details .details-info .creator-info span {
    display: block;
}

.details .creator-image-container {
    position: relative;
    width: 32px;
    height: 32px;
}

.details .badge {
    position: absolute;
    width: 32px;
}

.details .creator-image {
    position: absolute;
    left: 5px;
    top: 1px;
    width: 21px;
    height: 30px;
    border-radius: 4px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    margin: 0 auto 2px auto;
}

.details .episodes-container {
    background-color: whitesmoke;
    height: 100%;
    overflow-y: scroll;
    border-radius: 4px;
}

.details .episode-row {
    display: grid;
    grid-template-columns: 32px auto 1fr auto;
    grid-template-areas: "div div div";
    align-items: center;
    gap: 2px;
    border-bottom: #ccc 1px solid;
}

.details .episode-image {
    background-position: center;
    background-size: cover;
    width: 64px;
    height: 64px;
    margin: 4px;
    border-radius: 4px;
    position: relative;
}


.details .episode-image .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.25) 100%);
    border-radius: 4px;
    overflow: hidden;
}

.details .episode-number {
    margin: 4px;
    font-weight: 600;
    font-size: medium;
    text-align: right;
}

.details .episode-title {
    margin: 4px;
    font-size: small;
}

.details .crown {
    margin: 16px
}