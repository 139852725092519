.episode {
    background-repeat: repeat;
    display: grid;
    width: 100%;
    height: 100vh;
    background-color: whitesmoke;
    grid-template-rows: auto 1fr;
    grid-template-areas: "div" "div";
    margin: 0 auto;
}

.episode .episode-header {
    background-color: #6C81F0;
    width: 100%;
}

.episode .episode-content {
    margin: 16px;
}

.episode .episode-topbar {
    display: grid;
    grid-template-columns: 64px 1fr 64px;
}

.episode .episode-topbar .right {
    text-align: right;
}

.episode .episode-body {
    height: 100%;
    overflow-y: hidden;
}

.episode .episode-container {
    height: 100%;
    overflow-y: scroll;
    text-align: center;
    position: relative;
}

.episode .episode-container img {
    width: 100vw;
    max-width: 480px;
}

.episode .episode-postroll {
    background-color: whitesmoke;
}

.episode .episode-comments-bar {
    background-color: #6C81F0;
    display: grid;
}

.episode .episode-comments-bar h3 {
    margin: 8px 16px;
    text-align: left;
}

.episode .episode-comments {
    display: grid;
    background: #D8DBF8;
}

.episode .comment-field {
    display: grid;
    justify-content: left;
    grid-template-columns: 4fr 1fr;
    margin: 8px 16px;
    gap: 8px;
}

.episode .comment-field textarea {
    border: 0;
    border-radius: 4px;
    font-size: medium;
}

.episode .comment-row {
    display: grid;
    border: 0;
    border-top: 1px solid #666;
    background: #D8DBF8;
}

.episode .comment-row .comment-text {
    margin: 0 16px 16px 16px;
    text-align: left;
}

.episode .comment-row .comment-empty {
    margin: 16px;
    text-align: center;
}

.episode .comment-row .comment-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: right;
    font-size: small;
    color: #666;
    margin: 16px;
}

.episode .comment-row .comment-info img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 4px;
}


.episode .comment-row .comment-info .comment-name {
    font-weight: 600;
    text-align: left;
    color: black;
}

.episode .episode-nav {
    display: grid;
    margin: 8px 16px;
    grid-template-columns: 1fr 1fr;
    font-weight: 600;
    font-size: large;
    text-align: left;
}

.episode .episode-nav .next {
    text-align: right;
}

.episode .episode-nav img {
    width: 24px;
    height: 24px;
    vertical-align: bottom;
}

.episode .image-episode {
    pointer-events: none;
}

.episode .spinner-abs {
    position: absolute;
    left: calc(50% - 48px);
    top: calc(50% - 48px);
    background: rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 8px;
    width: 96px;
    color: white;
    font-weight: 600;
    transition: all;
}