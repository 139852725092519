.creator {
    background-image: url('../../assets/grid2.svg');
    background-repeat: repeat;
    background-size: 40px;
    background-position: center;
    display: grid;
    width: 100%;
    height: 100vh;
    background-color: whitesmoke;
    grid-template-rows: auto 1fr;
    grid-template-areas: "div" "div";
    max-width: 960px;
    margin: 0 auto;
}

.creator .creator-title {
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.creator .creator-title .right {
    text-align: right;
}

.creator .creator-header {
    padding: 16px;
    border-bottom: 1px solid black;
}

.creator .creator-header p {
    font-size: small;
    margin: 8px 0;
}

.creator .creator-info {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-areas: "div div div";
    align-items: center;
}

.creator .creator-name {
    margin: 0 16px 0 4px;
    font-weight: 600;
}

.creator .creator-name span {
    display: block;
    font-size: small;
    font-weight: 300;
}

.creator .creator-image-container {
    position: relative;
    width: 64px;
    height: 64px;
}

.creator .badge {
    position: absolute;
    width: 64px;
}

.creator .creator-actions {
    display: grid;
    gap: 8px;
    font-size: small;
}

.creator .creator-actions icon {
    width: 24px;
    margin-right: 4px;
}

.creator .creator-actions div {
    align-items: center;
    display: inline-flex;
}


.creator .creator-image {
    position: absolute;
    left: 11px;
    top: 3px;
    width: 42px;
    height: 58px;
    border-radius: 8px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    margin: 0 auto 4px auto;
}

.creator .creator-title {
    font-size: x-large;
    font-weight: 600;
}

.creator .creator-title div {
    height: 100%;
}

.creator .creator-title a {
    display: flex;
}

.creator .creator-body {
    background-color: #D8DBF8;
    height: 100%;
    overflow: hidden;
}

.creator .creator-series p {
    font-size: small;
    margin: 4px 0;
}


.creator .creator-series {
    height: 100%;
    overflow: hidden;
}

.creator .creator-series-row {
    display: grid;
    grid-template-columns: 96px auto;
    gap: 16px;
    font-size: small;
}

.creator .creator-series-header {
    margin: 8px 16px;
}

.creator .creator-series-list {
    display: grid;
    gap: 16px;
    margin: 16px 16px 64px 16px;
}

.creator .creator-series-container {
    height: 100%;
    overflow-y: scroll;
}

.creator .creator-donate,
.creator .creator-contact {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 8px;
}