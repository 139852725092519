.follow-button {
    background-color: #560EEF;
    padding: 4px 16px;
    border-radius: 12px;
    border: none;
    margin: 2px 0;
    color: whitesmoke;
    font-size: x-small;
}

.follow-button.unfollow {
    background-color: #333333;
}