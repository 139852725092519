body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "CaveatBrush";
  src: local("CaveatBrush-Regular"),
    url('./fonts/CaveatBrush-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Regular"),
    url('./fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "InterBlack";
  src: local("Inter-Black"),
    url('./fonts/Inter-Black.ttf') format('truetype');
}