* {
  margin: 0;
  padding: 0;
}

.App {
  display: grid;
  grid-template-rows: 60px 1fr 80px;
  grid-template-areas: "headerarea" "mainarea" "footerarea";
  font-family: Inter;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #D8DBF8;
}

.App.about-page {
  grid-template-rows: 60px 1fr;
  grid-template-areas: "headerarea" "mainarea"
}

@media (min-width: 480px) {
  .App {
    background: linear-gradient(90deg, #D8DBF8, black, #D8DBF8);
  }
}

header,
main,
footer {
  margin: 0 auto;
}

header {
  grid-area: headerarea;
  background-color: #6C81F0;
  width: 100%;
}

header.full {
  max-width: none;
}

header .row {
  display: grid;
  grid-template-columns: 16px 1fr 1fr 16px;
  grid-template-areas: "div div div div";
  height: 100%;
  width: 100%;
  align-items: center;
}

header .logo.full {
  margin-left: 16px;
}

header .header-right {
  text-align: right;
}

header .header-right .header-actions {
  gap: 16px;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

header .header-actions .notifications-bell {
  position: relative;
}

header .header-actions .notifications-indicator {
  width: 0px;
  height: 0px;
  border: 4px solid crimson;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
}

header .header-right .icon {
  width: 24px;
}

main {
  grid-area: mainarea;
  background-color: whitesmoke;
  width: 100%;
  overflow: hidden;
}

footer {
  grid-area: footerarea;
  background-color: #6C81F0;
  width: 100%;
}

footer .row {
  display: grid;
  grid-template-columns: 16px 1fr 1fr 1fr 16px;
  grid-template-areas: "div div div div div";
  height: 64px;
  align-items: center;
  /* width: calc(100vw - 32px); */
  width: 100%;
}

footer .main-link {
  text-align: center;
  border-radius: 8px;
  padding: 2px 0;
}

footer .main-link span {
  display: block;
  font-size: small;
}

footer .main-link.selected {
  background-color: rgba(255, 255, 255, 0.25);
  filter: invert();
}

footer img {
  width: 32px;
}

main>.content {
  height: calc(100vh - 60px - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
}

main .new-releases {
  margin: 16px;
}

main .content-listing {
  margin: 0;
}

main .items-row {
  display: inline-flex;
  gap: 8px;
  margin: 0 16px;
}

main .items-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

main .items-container::-webkit-scrollbar {
  display: none;
}

main .item-card {
  display: block;
  width: 96px;
  height: 160px;
  text-align: center;
}

main .item-card span {
  font-size: small;
  text-overflow: ellipsis;
  height: 2em;
  white-space: nowrap;
  width: 80px;
  display: block;
  overflow: hidden;
  margin: 2px auto;
}

main .item-card .item-cover {
  width: 96px;
  background-position: center;
  background-size: cover;
  height: 128px;
  border-radius: 4px;
  position: relative;
}

main .item-card .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.25) 100%);
  border-radius: 4px;
  overflow: hidden;
}

main .item-card .ranking {
  position: absolute;
  left: -12px;
  height: 48px;
  top: 72px;
}

main .item-card:first-child .ranking {
  left: -18px;
}

main .hero {
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}

@media (min-width: 920px) {
  main .hero {
    max-height: 480px;
    text-align: center;
  }
}

main .hero span {
  display: block;
  padding: 4px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
  color: white;
  font-size: small;
  font-weight: 600;
}

main .hero video {
  border-radius: 4px;
  border: 4px solid black;
  width: 100%;
}

@media (min-width: 920px) {
  main .hero video {
    width: auto;
    max-height: 480px;
  }
}

h2 {
  font-size: medium;
  margin: 0 0 8px 0;
}

main .content-listing h2 {
  margin: 0 0 8px 16px;
}

a {
  text-decoration: none;
  color: black;
}

.icon {
  width: 24px;
}

.empty-state {
  margin: 32px;
}

.empty-message {
  text-align: center;
  display: flex;
  justify-content: center;
}

.empty-message span {
  padding: 16px;
  margin: 32px 0;
  background: #dedede;
  border-radius: 8px;
}

.shadow-container {
  position: relative;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 8px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

button {
  background-color: #6C81F0;
  color: white;
  padding: 8px 16px;
  font-size: large;
  font-weight: 500;
  border-radius: 8px;
  border: 0;
  margin: 0 auto;
}

.loading-spinner {
  text-align: center;
  margin: 4px;
  display: grid;
  gap: 8px;
}

.spinner {
  width: 32px;
  height: 32px;
  border-radius: 2rem;
  border-top: 3px solid #6C81F0;
  border-left: 3px solid #6C81F0;
  border-right: 3px solid #6C81F0;
  border-bottom: 3px solid whitesmoke;
  animation: rotation 1s infinite linear;
  margin: auto;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centered {
  text-align: center;
}

.main-bio-container {
  display: grid;
  height: 100%;
  overflow-y: auto;
}

.main-bio {
  margin: 18px;
}

.main-bio .bio {
  overflow-y: visible;
}

.crown,
.guest {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.guest path,
.crown path {
  fill: #333333
}

.crown.full-member path {
  fill: #6C81F0
}