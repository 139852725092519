.modal {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.0);
    display: grid;
    z-index: 2;
}

.modal .modal-container {
    width: 65%;
    max-width: 320px;
    background: white;
    border-radius: 16px;
    margin: auto;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.75));
    transform: scale(0.5);
    opacity: 0.0;
    transition: transform 100ms, opacity 100ms;
    transition-timing-function: ease-in;
}

.modal.show {
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 200ms;
    transition-timing-function: ease-in;
}

.modal.show .modal-container {
    transform: scale(1.0);
    opacity: 1;
    transition: transform 200ms, opacity 200ms;
    transition-timing-function: ease-in;
}

.modal .modal-content {
    margin: 16px;
    text-align: center;
}

.modal .modal-content p {
    margin-bottom: 16px;
    font-size: small;
}

.modal .modal-content button {
    border: none;
    background-color: #D8DBF8;
    margin-bottom: 4px;
    padding: 8px 64px;
    border-radius: 4px;
    color: #560EEF;
}

.modal .modal-content img {
    vertical-align: middle;
    width: 12px;
}

.modal .modal-content .icon {
    background-position: center;
    background-size: cover;
    width: 14px;
    height: 14px;
    background-color: #eeeeee;
    display: inline-flex;
    vertical-align: bottom;
    margin: 2px;
    border-radius: 2px;
}

.modal .modal-content .icon.plus-square {
    background-image: url("../../assets/icons/plus-square.svg");
}

.modal .modal-content .icon.box-arrow-up {
    background-image: url("../../assets/icons/box-arrow-up.svg");
}

.modal .modal-content a {
    color: #560EEF;
    text-decoration: underline;
}

.modal-title {
    display: inline-grid;
    grid-template-columns: auto auto;
    gap: 4px;
    align-items: end;
}