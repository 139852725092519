.about {
    grid-area: mainarea;
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    background-color: whitesmoke;
    color: #000;
    gap: 32px;
}

.about .hero {
    display: grid;
    overflow: hidden;
    height: 420px;
    background-color: #000;
}

.about .hero-background {
    display: grid;
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5)), url(/public/images/hero-bg-3.jpg);
    background-position: center;
    background-size: cover;
    animation: zooming 1s;
    height: 100%;
    ;
}

.about .hero-text {
    animation: fadein 1s;
}

@keyframes zooming {
    from {
        scale: 1.25;
    }

    to {
        scale: 1.0;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1.0;
    }
}

.about .hero .content {
    display: grid;
    margin: 18px auto;
}

.about .content {
    max-width: 75%;
    margin: 0 auto;
}

.about .content .section-row {
    display: grid;
    grid-template-columns: none;
    grid-row: auto auto;
    gap: 16px;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
}

.about .content .section-row li {
    font-size: inherit;
    font-weight: inherit;
}

.about .content img {
    margin: auto;
}

.about .hero-text {
    text-align: center;
    filter: drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.5));
    color: #fff;
    margin: auto;
}

.about button {
    font-size: 24px;
    font-weight: 600;
    margin: 16px;
    cursor: pointer;
    transform: scale(1.0);
    transition: transform .25s ease-out;
}

.about button:hover {
    transform: scale(1.1);
    transition: transform .25s ease-out;
}


.about h1 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 16px;
}

.about h2 {
    font-weight: 600;
    font-size: 18px;
}

.about h3 {
    font-weight: 600;
    font-size: 14px;
}

.about ol,
.about ul {
    margin-left: 32px;
    font-weight: 500;
    font-size: 14px;
}

.about .align-right {
    text-align: right;
}

.about .install {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    gap: 8px;
}

.about .install img {
    transform: scale(1.0);
    transition: transform .25s ease-out;
}

.about .install img:hover {
    transform: scale(1.15);
    transition: transform .25s ease-out;
}

.about .install>div {
    text-align: center;
}

.about .start-making {
    text-align: center;
}

.about .start-making h2 {
    margin-top: 32px;
}


.bio {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    gap: 32px;
    font-size: 18px;
    font-weight: 500;
}

.bio img {
    width: 240px;
    border-radius: 16px;
}

.about .footer-section {
    background-color: #969DE3;
}

.about .footer-section .footer-row {
    margin: 16px 0;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    font-size: 18px;
    gap: 8px;
    align-items: center;
}

.about .footer-section .footer-row button {
    font-size: 24px;
    font-weight: 600;
}

.about .footer-section .footer-row:last-child {
    margin-bottom: 64px;
}

.about .footer-section a {
    color: #000;
    vertical-align: middle;
}

.about .footer-section svg {
    fill: #fff;
    width: 32px;
    height: 32px;
}

.about .footer-section .contacts {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 8px;
}

.about .footer-section .socials {
    display: grid;
    grid-template-columns: repeat(3, 32px);
    gap: 16px;
}

.about .wide-image {
    width: 320px;
}

.about .medium-image {
    width: 240px;
}

.about .install-image {
    width: 200px;
}

.about .actions {
    display: grid;
}

.about .actions button {
    margin: 8px;
}

.about .actions>div {
    display: grid;
}

@media (min-width: 640px) {
    .about .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px
    }

    .about .actions>div:first-child {
        text-align: right;
    }

    .about .actions>div:last-child {
        text-align: left;
    }
}



.about .actions .get-started {
    background-image: linear-gradient(#6C81F0, #3e49ab);
}

.about .donate {
    background-image: linear-gradient(#efcb5e, #cf943d);
}


.about .install-text {
    display: grid;
    gap: 8px;
}

.about .install-mode {
    display: inline-grid;
    align-items: center;
    background-color: #3e49ab;
    border-radius: 8px;
    color: white;
    background-image: linear-gradient(#6C81F0, #3e49ab);
    cursor: pointer;
    margin: 4px 0;
}

.about .install-mode:hover {
    transform: scale(1.1);
    transition: transform .25s ease-out;
}

.about .install-mode>div {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    margin: 8px 12px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.about .install-mode svg {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.about .install-image {
    border-radius: 12px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0.025));
}

@media (min-width: 640px) {
    .about .hero {
        height: 480px;
    }

    .about .wide-image {
        width: 480px;
    }

    .about .install {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(2, 2fr);
        gap: 8px;
    }

    .about ol,
    .about ul {
        font-weight: 600;
        font-size: 18px;
    }

    .about .install-mode>div {
        font-size: 18px;
    }
}

@media (min-width: 720px) {
    .about .wide-image {
        width: 640px;
    }

    .about .medium-image {
        width: 400px;
    }

    .about h1 {
        font-weight: 600;
        font-size: 64px;
        margin-bottom: 16px;
    }

    .about h2 {
        font-weight: 600;
        font-size: 24px;
    }

    .about h3 {
        font-weight: 600;
        font-size: 18px;
    }

    .about .content .section-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row: none;
    }

    .about .install {
        display: grid;
        grid-template-rows: none;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    }


    .about .install-image {
        width: 150px;
    }

    .bio {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 32px;
        font-size: 18px;
        font-weight: 500;
    }

    .bio img {
        width: 300px;
    }

    .about .footer-section .footer-row {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 18px;
    }

}

@media (min-width: 1080px) {
    .about .content {
        max-width: 960px;
    }

    .about .wide-image {
        width: 720px;
    }

    .about .install-image {
        width: 200px;
    }

}