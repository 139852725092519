.idcard {
    height: calc(100vh - 60px - 80px);
    overflow-y: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas: "idcardheader" "idcardbody";

}

.idcard .idcard-header {
    grid-area: idcardheader;
}

.idcard .idcard-body {
    grid-area: idcardbody;
    height: 100%;
    overflow-y: scroll;
    background-color: #D8DBF8;
}

.idcard .idcard-top-banner {
    text-align: center;
    margin: 16px 0;
}

.idcard .idcard-top-banner h2 {
    font-size: xx-large;
}

.idcard .idcard-top-banner .icon {
    width: 128px;
    margin: 0 auto;

}

.idcard .idcard-top-banner button {
    margin-top: 8px;
    ;
}

.idcard .idcard-top-banner label {
    margin: auto 4px;
    font-size: medium;
    font-weight: bold;
    text-align: left;
}

.idcard .idcard-top-banner input {
    font-size: medium;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    text-align: left;
}

.idcard .idcard-container {
    margin: 16px;
}

.idcard .field-row {
    margin: 8px 16px;
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.idcard .field-section {
    text-align: left;
}

.split-tab {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 250px;
    text-align: center;
    background-color: white;
    color: #ccc;
    border-radius: 8px;
    overflow: hidden;
    font-weight: bold;
    border: 2px solid #ccc;
}

.split-tab-choice {
    padding: 8px 16px;
    cursor: pointer;
}

.split-tab .split-tab-choice:hover {
    background-color: #ccc;
    color: black;
}

.split-tab .split-tab-choice.selected {
    background-color: #6C81F0;
    color: white;
}

button:disabled {
    cursor: not-allowed;
    filter: opacity(.5);
}

.locker-combo {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 8px;
}

.id-card-container {
    position: relative;
    display: inline-block;
}

.verified-icon {
    position: absolute;
    bottom: 32px;
    right: 0;
    fill: green;
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid white;
    border-radius: 12px;
}

.idcard .member-info {
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    margin: 8px;
    gap: 4px
}

.idcard .checkbox-item {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px
}

.idcard input[type='checkbox'] {
    width: 16px;
}

.idcard a {
    text-decoration: underline;
}