.alert {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 100px;
    transform: scale(0.5);
    opacity: 0.0;
    transition: transform 100ms, opacity 100ms;
    transition-timing-function: ease-in;
}

.alert.show {
    transform: scale(1.0);
    opacity: 1;
    transition: transform 200ms, opacity 200ms;
    transition-timing-function: ease-in;
}

.alert span {
    padding: 8px 16px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    font-weight: 600;
    color: white;
}